import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { arca_req, configsMain_req, getFeautered_req } from "../api/api";
import Page from '../components/Page'
import CategoryItem from "../components/categoryItem";
import ProductItem from "../components/productItem";
import Title from "../elements/title";
import { mainBlockData } from "../redux/main.slice";
import { useIntl } from "react-intl";
import Popup from "../components/popup";
import CheckGreenIcon from "../assets/images/icons/checked-green.png";
import BanIcon from "../assets/images/icons/ban.png";
import CloseDarkIcon from "../assets/images/icons/close-dark-icon.png";
// import OwlCarousel from "react-owl-carousel2";
// import "../../node_modules/react-owl-carousel2/lib/styles.css";

export default function Home() {
    const router = useRouter();
    const intl = useIntl();
    const carouselRef = useRef();
    const orderId = router.query;
    // const [configState, setConfigState] = useState(null);
    const [successPopup, setSuccessPopup] = useState(false);
    const [error, setError] = useState(null);
    const [featured, setFeatured] = useState(null);
    const locale = useSelector((state) => state.global.locale);
    const configState = useSelector((state) => state.main.data);
    const dispatch = useDispatch();
    const options = {
        items: 1,
        nav: true,
        rewind: true,
        autoplay: true,
        autoplayTimeout: 5000,
    };
    // const configDataReq = async () => {
    //   const configData = await configsMain_req();

    //   dispatch(mainBlockData(configData));
    //   setConfigState(configData);
    // };
    const getFeatured = async () => {
        const response = await getFeautered_req();
        setFeatured(response.data?.featuredProducts);
    };

    useEffect(() => {
        getFeatured();
    }, [locale]);
    // const nardi = JSON?.parse(configState?.pinnedCategoryGroups?.[0]?.categories);
    // const shaxmat = JSON?.parse(
    //   configState?.pinnedCategoryGroups?.[1]?.categories
    // );
    const closePopup = () => {
        setSuccessPopup(!successPopup);
    };

    const arcaOrder = async () => {
        try {
            const arcaOrderData = await arca_req(orderId);

            if (arcaOrderData.data.success) {
                setSuccessPopup(!successPopup);
            }
        } catch (e) {
            setError(e.response.data.message);
            setSuccessPopup(!successPopup);
        }
    };
    useEffect(() => {
        if (orderId?.orderId) {
            arcaOrder();
        }
    }, [orderId]);

    // useEffect(() => {
    //   configDataReq();
    // }, [locale]);

    // const contentBlock = configState?.sliderImages[0];

    return (
        <Page meta={{
            title: 'Շախմատների և Նարդիների Խանութսրահ | Handle.am',
            description: 'Գնեք ամենաորակյալ շախմատները և նարդիները, փայտե ժամացույցները, խաղաքարերը, տակառները և հուշանվերները Handle.am - ից'
        }}>
            <div id="home-page" className="page">
                <div
                    className="main"
                    style={{
                        backgroundImage: `url(${configState?.headerImage})`,
                    }}
                >
                    {/*<OwlCarousel ref={carouselRef} options={options}>
            <div>
              <img src={configState?.headerImage} alt="The Last of us" />
              Text 1
            </div>
            <div>
              <img src={configState?.headerImage} alt="GTA V" />
              Text 2
            </div>
            <div>
              <img src={configState?.headerImage} alt="Mirror Edge" />
              Text 3
            </div>
          </OwlCarousel>*/}
                    <div className={`main-content flex flex-ai-end`}>
                        <div className="wrapper">
                            {/*{locale === 1 && (
                <h4>
                  <span>Սև Ուրբաթ</span> 25/26/27 <br /> 30%{" "}
                  <b>զեղչ ողջ տեսականու համար</b>
                </h4>
              )}*/}

                            {/*{locale === 3 && (
                <h4>
                  <span>შავი პარასკევი</span> 25/26/27 <br /> <b>ნოემბერი </b>{" "}
                  30%
                </h4>
              )}*/}

                            <>
                                <h1>{configState?.headerImageContent?.title}</h1>
                                <p>{configState?.headerImageContent?.text}</p>
                            </>
                            {/*Arm*/}
                            {/*<h4>
                <span>Սև Ուրբաթ</span> 25/26/27 <br/> 30% <b>զեղչ ողջ տեսականու համար</b>
              </h4>*/}
                            {/*Ge*/}
                            {/*<h4>
                <span>შავი პარასკევი</span> 25/26/27 <br/> <b>ნოემბერი </b> 30%
              </h4>*/}
                            {/*Rus and ENG*/}
                            {/*<h1>{configState?.headerImageContent?.title}</h1>*/}
                        </div>
                    </div>
                </div>
                <div className="categories pt-60 pb-40">
                    <div className="wrapper">
                        <Title title={`${intl.messages.products}`} />
                        <div className="flex flex-wrap flex-jc-between">
                            {configState?.pinnedCategories?.map((item, i) => (
                                <CategoryItem
                                    id={item.id}
                                    title={item.name}
                                    image={item.image}
                                    description={item.description}
                                    key={i}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div className="products pt-40 pb-40">
                    <div className="wrapper">
                        <Title title={`${intl.messages.featuredCollections}`} />
                        <div className="flex flex-wrap">
                            {featured?.map((item, i) => (
                                <ProductItem
                                    image={item.image}
                                    name={item.title}
                                    size={item?.size}
                                    price={item.price}
                                    code={item.code}
                                    categoryName={item.categoryName}
                                    tag={item.tag}
                                    oldPriceData={false}
                                    id={item.id}
                                    currency={item.currency}
                                    key={i}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                {/*<div className="discounted-banner">
          <div className="wrapper">
            <div className="discounted-banner-content">
              <h2>{intl.messages.discountedCollections}</h2>
            </div>
          </div>
        </div>*/}
                <div className="products pt-40 pb-40">
                    <div className="wrapper">
                        <Title title={`${intl.messages.discountedCollections}`} />
                        <div className="flex flex-wrap">
                            {configState?.productsWithDiscount?.map((item, i) => (
                                <ProductItem
                                    image={item.image}
                                    name={item.title}
                                    size={item.size}
                                    price={item.price}
                                    code={item.code}
                                    categoryName={item.categoryName}
                                    tag={item.discount}
                                    oldPriceData={true}
                                    id={item.id}
                                    currency={item.currency}
                                    key={i}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Popup trigger={successPopup}>
                <>
                    <button className="close-button" onClick={closePopup}>
                        <img src={CloseDarkIcon.src} alt="" />
                    </button>
                    <div className="popup-body">
                        <div className="payment-success">
                            {error ? (
                                <h4>
                                    <img src={BanIcon.src} alt="" />
                                    {error}
                                </h4>
                            ) : (
                                <h4>
                                    <img src={CheckGreenIcon.src} alt="" />
                                    {intl.messages.paymentSuccessMessage}
                                </h4>
                            )}
                        </div>
                        <div className="popup-buttons">
                            <button onClick={closePopup}>{intl.messages.close}</button>
                        </div>
                    </div>
                </>
            </Popup>
        </Page>
    );
}
